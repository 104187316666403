import styled from 'styled-components';

export default styled.p`
    font-size: 1.2em;
    margin-bottom: ${({ marginBottom }) => marginBottom || '1em'};

    @media only screen and (min-width: 769px) {
        font-size: 1.5em;
    }
`;
