import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// eslint-disable-next-line react/jsx-props-no-spreading
export default styled(props => <Link {...props} />)`
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    border: 1px solid black;
    padding: 1em;
    font-size: 1.2em;
    position: relative;
    max-width: 15em;
    text-align: center;
    transition: background 0.3s;
    min-width: 12em;

    &:after {
        top: 50%;
        left: 0.1em;
        right: 0.1em;
        height: calc(100% + 0.33em);
        bottom: auto;
        content: '';
        position: absolute;
        transform: translateY(-50%);
        border: 1px solid black;
    }

    &:hover {
        background-color: black;
        color: white;
    }

    @media only screen and (max-width: 600px) {
        font-size: 1em;
    }
`;
