import styled from 'styled-components';

export default styled.h1`
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.5em;
    margin-bottom: 1em;

    @media only screen and (min-width: 769px) {
        font-size: 2em;
    }
`;
