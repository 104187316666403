import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from './Header';
import Paragraph from './Paragraph';

const Wrapper = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor || '#eee'};
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 6em 1em;
`;

const Container = styled.div`
    text-align: center;
    position: relative;

    &:before {
        content: '';
        display: block;
        height: 3em;
        width: 1px;
        background-color: black;
        position: absolute;
        top: -4.5em;
        left: 50%;
        z-index: 2;
    }
    &:after {
        content: '';
        display: block;
        height: 3em;
        width: 1px;
        background-color: black;
        position: absolute;
        bottom: -4.5em;
        left: 50%;
        z-index: 2;
    }

    @media only screen and (min-width: 769px) {
        max-width: 40vw;
    }
`;

const TextSection = ({ title, description, backgroundColor }) => (
    <Wrapper backgroundColor={backgroundColor}>
        <Container>
            <Header>{title}</Header>
            <Paragraph marginBottom="0">{description}</Paragraph>
        </Container>
    </Wrapper>
);

TextSection.propTypes = {
    backgroundColor: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
};

export default TextSection;
