import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from './Header';
import Paragraph from './Paragraph';

const Image = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${({ image }) => image});
    background-size: cover;
    transition: all 5s;

    &:hover {
        transform: scale(1.1);
    }
`;
const ImageContainer = styled.div`
    min-height: 15em;
    height: 30vw;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1em;
`;

const TextContainer = styled.div`
    overflow: hidden;
    text-align: center;

    @media only screen and (min-width: 769px) {
        height: 20em;
    }
    @media only screen and (min-width: 993px) {
        height: 15em;
    }
`;

const Container = styled.div``;

const Card = ({ title, description, image }) => (
    <Container>
        <ImageContainer>
            <Image image={image} />
        </ImageContainer>
        <TextContainer>
            <Header>{title}</Header>
            <Paragraph>{description}</Paragraph>
        </TextContainer>
    </Container>
);

Card.propTypes = {
    description: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
};

export default Card;
