import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { InfoWindow, Marker } from 'google-maps-react';

import styled from 'styled-components';
import Layout from '../components/Layout';
import Map from '../components/Map';
import StyledLink from '../components/StyledLink';
import Header from '../components/Header';
import TextSection from '../components/TextSection';
import Card from '../components/Card';
import Paragraph from '../components/Paragraph';
import BlogRoll from '../components/BlogRoll';
import free from '../../static/img/free.svg';
import medal from '../../static/img/medal.svg';
import fast from '../../static/img/fast.svg';
import worker from '../../static/img/worker.svg';

const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
`;

const ImageSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-image: url(${({ image }) => image || 'none'});
    background-size: cover;
    box-sizing: border-box;

    @media only screen and (min-width: 769px) {
        height: 40em;
        padding: 3em;
    }
`;

const ImageBox = styled.div`
    background-image: url(${({ image }) => image});
    background-size: cover;
    min-height: 20em;
    width: 100%;

    @media only screen and (min-width: 769px) {
        display: none;
        background-position: center;
    }
`;

const Box = styled.div`
    background-color: white;
    min-height: 20em;
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media only screen and (min-width: 769px) {
        width: 40em;
        justify-content: left;
        text-align: left;
    }
`;

const Icon = styled.div`
    height: 2em;
    width: 2em;
    margin-right: 0.5em;
    background-image: url(${({ source }) => source});
    background-repeat: no-repeat;
`;

const Info = styled.span`
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.8em;
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0.5em;
    width: 100%;

    @media only screen and (min-width: 769px) {
        margin: 1.5em;
    }
    @media only screen and (min-width: 993px) {
        width: auto;
    }
`;

const IconsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 50%;

    @media only screen and (min-width: 769px) {
        justify-content: ${({ justify }) => justify || 'center'};
        flex-wrap: no-wrap;
    }
`;

const IconsWrapper = styled.div`
    padding: 3em;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
        text-align: center;
    }
`;

const ImagesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    padding: 1em;

    @media only screen and (min-width: 769px) {
    }
`;

const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
    margin-bottom: 1em;
    &:last-of-type {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 769px) {
        width: 50%;
        height: 50vh;
        max-height: 30vw;
        padding: 0 0.5em;
    }
`;

const ImageContainer = styled.div`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    position: relative;
    overflow: hidden;
`;

const Image = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${({ image }) => image});
    background-size: cover;
    transition: all 5s;

    &:hover {
        transform: scale(1.1);
    }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const ImageLink = styled(props => <Link {...props} />)`
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    color: white;
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.8);
`;

const BlogSection = styled.div`
    text-align: center;
    box-sizing: border-box;
    padding: 1em;
`;

const InfoSection = styled.div`
    box-sizing: border-box;
    padding: 1em;
    background-color: #eee;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    position: relative;

    @media only screen and (min-width: 993px) {
        padding: 3em;
    }
`;

const ImageWithPadding = styled.div`
    background-image: url(${({ image }) => image || 'none'});
    background-size: cover;
    min-height: 15em;
    height: 30vw;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width: 993px) {
        height: 30em;
        background-position: center;
    }
`;

const Wrapper = styled.div`
    text-align: center;
    box-sizing: border-box;
    padding: 1em;

    @media only screen and (min-width: 993px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6em;
        background-color: white;
        width: 30em;
        text-align: left;
    }
`;

const LinkWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 993px) {
        justify-content: left;
    }
`;

const GalleryImage = styled.img`
    display: block;
    width: ${({ sizes = {} }) => sizes.width || '100%'};
    height: ${({ sizes = {} }) => sizes.height || '100%'};
    padding: 1em;
    box-sizing: border-box;
    object-fit: cover;
`;

const GalleryContainer = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 1em;
    flex-wrap: wrap;
    background-color: #eee;

    @media only screen and (min-width: 993px) {
        padding: 3em;
    }
`;

const MobileImage = styled.div`
    background-image: url(${({ image }) => image});
    background-size: cover;
    min-height: 15em;
    height: 50vw;
    width: 100%;
    margin-bottom: 1em;

    @media only screen and (min-width: 769px) {
        display: none;
    }
`;

const GalleryTextWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

const GalleryWrapper = styled.div`
    display: flex;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const CardSection = styled(Section)`
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-wrap: wrap;

    @media only screen and (min-width: 769px) {
        padding: 2em 0;
        flex-wrap: nowrap;
    }
`;

const CardWrapper = styled.div`
    padding: 1em;
`;

export const IndexPageTemplate = ({
    images,
    paragraphs,
    cards,
    gallery,
    sections,
    icons,
    labels: { show, about, mapMarker, tel, mapInfo, realizations, contact },
    offers,
}) => {
    const [infoMarker, setMarker] = useState();

    return (
        <div>
            <ImageSection image={images[0]}>
                <ImageBox image={images[0]} />
                <Box>
                    <Header>
                        {sections[0].title} <br /> {sections[0].additionalTitle}
                    </Header>
                    <Paragraph>{sections[0].description}</Paragraph>
                    <StyledLink to="/blog">{show}</StyledLink>
                </Box>
            </ImageSection>
            <IconsWrapper>
                <IconsContainer justify="flex-end">
                    <InfoWrapper>
                        <Icon source={free} />
                        <Info>{icons[0].label}</Info>
                    </InfoWrapper>
                    <InfoWrapper>
                        <Icon source={medal} />
                        <Info>{icons[1].label}</Info>
                    </InfoWrapper>
                </IconsContainer>
                <IconsContainer justify="flex-start">
                    <InfoWrapper>
                        <Icon source={fast} />
                        <Info>{icons[2].label}</Info>
                    </InfoWrapper>
                    <InfoWrapper>
                        <Icon source={worker} />
                        <Info>{icons[3].label}</Info>
                    </InfoWrapper>
                </IconsContainer>
            </IconsWrapper>
            <ImagesWrapper>
                {offers.map(({ label, image, link }) => (
                    <Container>
                        <ImageContainer>
                            <Image image={image} />
                            <ImageLink to={`/${link}`}>{label}</ImageLink>
                        </ImageContainer>
                    </Container>
                ))}
            </ImagesWrapper>
            <TextSection
                description={paragraphs[0].description}
                title={paragraphs[0].title}
            />
            <BlogSection>
                <Header>{realizations}</Header>
                <BlogRoll />
            </BlogSection>
            <InfoSection>
                <ImageWithPadding image={images[3]} />
                <Wrapper>
                    <Header>
                        {sections[1].title} <br /> {sections[1].additionalTitle}
                    </Header>
                    <Paragraph>{sections[1].description}</Paragraph>
                    <LinkWrapper>
                        <StyledLink to="/about">{about}</StyledLink>
                    </LinkWrapper>
                </Wrapper>
            </InfoSection>
            <TextSection
                backgroundColor="#fff"
                description={paragraphs[1].description}
                title={paragraphs[1].title}
            />
            <GalleryContainer>
                <GalleryTextWrapper>
                    <Header>{gallery.title}</Header>
                    <Paragraph>{gallery.description}</Paragraph>
                </GalleryTextWrapper>
                {gallery.images.map(image => (
                    <MobileImage image={image} />
                ))}
                <GalleryWrapper>
                    <GalleryImage
                        sizes={{
                            height: '50vw',
                            width: '50%',
                        }}
                        src={gallery.mobileImages[0]}
                    />
                    <div>
                        <GalleryImage
                            sizes={{
                                height: '25vw',
                                width: '100%',
                            }}
                            src={gallery.mobileImages[1]}
                        />
                        <GalleryImage
                            sizes={{
                                height: '25vw',
                                width: '100%',
                            }}
                            src={gallery.mobileImages[2]}
                        />
                    </div>
                </GalleryWrapper>
            </GalleryContainer>
            <TextSection
                backgroundColor="#fff"
                description={paragraphs[2].description}
                title={paragraphs[2].title}
            />
            <ImageSection image={images[2]}>
                <ImageBox image={images[2]} />
                <Box>
                    <Header>
                        {sections[2].title} <br /> {sections[2].additionalTitle}
                    </Header>
                    <Paragraph>{sections[2].description}</Paragraph>
                    <StyledLink to="/blog">{show}</StyledLink>
                </Box>
            </ImageSection>
            <TextSection
                backgroundColor="#fff"
                description={paragraphs[3].description}
                title={paragraphs[3].title}
            />
            <InfoSection>
                <ImageWithPadding image={images[7]} />
                <Wrapper>
                    <Header>
                        {sections[3].title} <br /> {sections[3].additionalTitle}
                    </Header>
                    <Paragraph>{sections[3].description}</Paragraph>
                    <LinkWrapper>
                        <StyledLink to="/contact">{contact}</StyledLink>
                    </LinkWrapper>
                </Wrapper>
            </InfoSection>
            <TextSection
                backgroundColor="#fff"
                description={paragraphs[4].description}
                title={paragraphs[4].title}
            />
            <CardSection>
                {cards.map(card => (
                    <CardWrapper>
                        <Card
                            description={card.description}
                            image={card.image}
                            title={card.title}
                        />
                    </CardWrapper>
                ))}
            </CardSection>
            <Map
                coordinates={{
                    lat: 51.3542425,
                    lng: 17.7999655,
                }}
                styles={{
                    height: '50vh',
                    position: 'relative',
                }}
                zoom={11}
            >
                <Marker
                    name={mapMarker}
                    onClick={(_, marker) => setMarker(marker)}
                    position={{
                        lat: 51.3542425,
                        lng: 17.7999655,
                    }}
                />
                <InfoWindow marker={infoMarker} onClose={() => {}} visible>
                    <div>
                        <h4>{`${mapInfo}: ${tel}`}</h4>
                    </div>
                </InfoWindow>
            </Map>
        </div>
    );
};

IndexPageTemplate.propTypes = {
    cards: PropTypes.shape({
        description: PropTypes.string,
        map: PropTypes.func,
        title: PropTypes.string,
    }),
    description: PropTypes.string,
    gallery: PropTypes.shape({
        description: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.string),
        mobileImages: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
    }),
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        })
    ),
    images: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.shape({
        about: PropTypes.string,
        contact: PropTypes.string,
        mapInfo: PropTypes.string,
        mapMarker: PropTypes.string,
        realizations: PropTypes.string,
        show: PropTypes.string,
        tel: PropTypes.string,
    }),
    offers: PropTypes.shape({
        map: PropTypes.func,
    }),
    paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    sections: PropTypes.shape({
        additionalTitle: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
    }),
    title: PropTypes.string,
};

const mapImages = images =>
    images.map(image => image.image.childImageSharp.fluid.src);

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const {
        markdownRemark: {
            frontmatter: {
                images,
                cards,
                gallery,
                sections,
                paragraphs,
                icons,
                labels,
                offers,
            },
        },
    } = data;
    const mappedImages = mapImages(images);
    const mappedCards = cards.map(({ title, description, image }) => {
        return {
            description,
            title,
            image: image.childImageSharp.fluid.src,
        };
    });
    const mappedOffers = offers.map(({ image, ...rest }) => {
        return {
            image: image.childImageSharp.fluid.src,
            ...rest,
        };
    });

    const mappedGallery = {
        title: gallery.title,
        description: gallery.description,
        images: mapImages(gallery.images),
        mobileImages: mapImages(gallery.mobileImages),
    };

    return (
        <Layout>
            <IndexPageTemplate
                cards={mappedCards}
                gallery={mappedGallery}
                icons={icons}
                images={mappedImages}
                labels={labels}
                offers={mappedOffers}
                paragraphs={paragraphs}
                sections={sections}
                title={frontmatter.title}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                images {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                paragraphs {
                    title
                    description
                }
                cards {
                    title
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                offers {
                    label
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 64) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    link
                }
                gallery {
                    title
                    description
                    images {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 64) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    mobileImages {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 64) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                sections {
                    additionalTitle
                    title
                    description
                }
                icons {
                    label
                }
                labels {
                    show
                    about
                    mapMarker
                    tel
                    mapInfo
                    realizations
                    contact
                }
            }
        }
    }
`;
