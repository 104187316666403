import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (min-width: 993px) {
        flex-wrap: nowrap;
    }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const MobileCardWrapper = styled(props => <Link {...props} />)`
    text-decoration: none;
    // @media only screen and (max-width: 992px) {
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     margin-bottom: 1em;
    // }
`;

const Card = styled.div`
    position: relative;
    height: calc(100vw - 2em);
    width: calc(100vw - 2em);
    max-height: 30em;
    max-width: 30em;
    background-image: url(${props => props.img || 'none'});
    background-size: cover;
    margin: 2em 0 1em 0;

    @media only screen and (min-width: 993px) {
        height: 18em;
        width: 18em;
        margin: 2em 1em 1em 1em;
    }

    @media only screen and (min-width: 1201px) {
        height: 20em;
        width: 20em;
        margin: 2em 2em 1em 2em;
    }
`;

const Title = styled.span`
    top: -2em;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: black;
    margin-bottom: 1em;
    position: absolute;
    height: 1em;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
`;

const BlogRoll = props => {
    const { data } = props;
    const { edges: posts } = data.allMarkdownRemark;
    const cards = posts.slice(0, 3).map(
        (
            {
                node: {
                    frontmatter: { featuredimage, title },
                    fields: { slug },
                },
            },
            index
        ) => {
            const img =
                featuredimage && featuredimage.childImageSharp.fluid.src;
            return (
                <MobileCardWrapper to={`/${slug}`}>
                    <Card img={img}>
                        <Title>
                            #{index + 1} {title}
                        </Title>
                    </Card>
                </MobileCardWrapper>
            );
        }
    );
    return <CardsContainer>{cards}</CardsContainer>;
};

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
};

export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date(formatString: "MMMM DD, YYYY")
                                featuredpost
                                featuredimage {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <BlogRoll count={count} data={data} />}
    />
);
